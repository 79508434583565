import React, { useEffect } from "react";
import { logViewMain } from "@/businesslogics/_common/logging";
import Head from "next/head";
import { checkPlatform } from "@/businesslogics/_common/utils/checkPlatform";
import styled from "@emotion/styled";
import HomePage from "../src/components/templates/home/Page";
import { HomeV4 } from "src/components/organisms/home/v4";
import { DisplayResolution } from "src/styles/themes/device";

export type Platform = "pc" | "mobile";
const Home = () => {
  useEffect(() => {
    logViewMain();
  }, []);

  return (
    <>
      <Head>
        <title>스파르타코딩클럽 - 코딩, 5주만에 왕초보 탈출!</title>
        <meta
          name="description"
          content="2023 올해의 브랜드 대상 코딩 교육 부문 1위! 코딩 왕초보를 위한 쉽고 재밌는 강의로 스파르타코딩클럽에서 지금 코딩 시작하세요."
        />
        <meta
          name="keywords"
          content="웹개발, 코딩공부, 프로그래밍, 파이썬, 코딩학원, 코딩, 코딩 스터디, 컴퓨터, 부트캠프, 개발자, 취업, 사이드 프로젝트, 개발자, 개발자 취업, 스터디, AWS, 웹서버, 왕초보, 서버, 클라이언트, 자바스크립트, html, css"
        />
        <meta
          name="naver-site-verification"
          content="0a8a7f208437cf8e9bc8861b6cdfe859fe0b2787"
        />
        <meta property="og:url" content={process.env.BASE_URL} />
        <meta property="og:title" content="스파르타코딩클럽" />
        <meta
          property="og:description"
          content="5주 완성! 코딩을 전혀 모르는 비개발자 대상의 웹개발 강의"
        />
        <meta property="og:image" content={`/css/images/scc-og.jpg`} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Product",
              name: "스파르타코딩클럽",
              image: "https://static.spartacodingclub.kr/meta/scc-og.jpg",
              description:
                "누구나 큰일 내는 세상을 위한, 모두를 위한 SW교육. 스파르타코딩클럽이 함께하겠습니다",
              url: "https://spartacodingclub.kr/",
              brand: {
                "@type": "Brand",
                name: "스파르타코딩클럽",
                logo: "https://static.spartacodingclub.kr/meta/scc-logo.png",
              },
              offers: {
                "@type": "Offer",
                priceCurrency: "KRW",
                price: "0",
              },
              sameAs: [
                "https://www.youtube.com/channel/UC2Fh3XoQYNYTba-cigq5cAA",
                "https://www.youtube.com/channel/UCi2LvS1cmM8mCZwBC1DiOPg",
                "https://www.youtube.com/channel/UCUHPD2E7FtSBcQO4KRd_yQg",
                "https://www.instagram.com/spartacodingclub",
                "https://www.instagram.com/iamrtanny/",
                "https://www.instagram.com/hanghae99/",
                "https://www.facebook.com/spartacodingclub",
                "https://blog.naver.com/spartacoding",
                "https://brunch.co.kr/@sparta",
              ],
            }),
          }}
        />
      </Head>
      <HomePage>
        <HeroContainer>
          <HomeV4.Hero />
          <HomeV4.BannerCarousel />
          <HomeV4.Product />
        </HeroContainer>
        <HomeV4.SpartaStartToEnd />
        <HomeV4.Strength />
        <HomeV4.KdcRecommend />
        <HomeV4.BootCamp />
      </HomePage>
    </>
  );
};

export function getServerSideProps({ req }) {
  const platform = checkPlatform(req.headers["user-agent"]);
  return { props: { platform } };
}

export default Home;

const HeroContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding-bottom: 85px;

  ${DisplayResolution.TabletAndDesktop} {
    display: inline-flex;
    padding-bottom: 100px;
    align-items: center;
    gap: 100px;
    width: 100vw;
  }
`;
