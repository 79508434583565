import { LinkBtn } from "src/components/atoms/Icons/LinkBtn/LinkBtn.svg";
import * as S from "./kdcAccordionCard.style";
import { NextImageWithFallback } from "src/components/atoms/_common/NextImageWithFallback/NextImageWithFallback";
import { KdcRecommendType } from "src/models/home/homeV4/KdcRecommend.models";
import { useRouter } from "next/router";
import { sendLogV2 } from "src/businesslogics/_common/loggingV2";

type Props = {
  isExpanded: boolean;
  course: KdcRecommendType;
  handleExpand: () => void;
};

export const KdcAccordionCard = ({
  isExpanded,
  course,
  handleExpand,
}: Props) => {
  const router = useRouter();
  const onClickItem = (courseTitle) => {
    sendLogV2("lecture_courseCuration_card_click", router.pathname, {
      course_title: courseTitle,
    });
    handleExpand();
  };
  const onClickLinkBtn = (courseTitle, nextUrl) => {
    sendLogV2("lecture_courseCuration_apply_click", router.pathname, {
      course_title: courseTitle,
    });
    router.push(nextUrl);
  };

  return (
    <S.Container
      isExpanded={isExpanded}
      onClick={() => {
        onClickItem(course.courseTitle);
      }}
      bgColor={course.bgColor}
    >
      <S.ExpandedContainer isExpanded={isExpanded}>
        <S.LeftContainer>
          <S.TitleContainer>
            <S.CourseTitleBadge>
              <S.CourseTitle>{course.courseTitle}</S.CourseTitle>
            </S.CourseTitleBadge>
            <S.Title>{course.subTitle}</S.Title>
            <S.Desc>{course.description}</S.Desc>
          </S.TitleContainer>
          <S.LinkBtnWrap
            onClick={() => {
              onClickLinkBtn(course.courseTitle, course.nextUrl);
            }}
          >
            <LinkBtn />
          </S.LinkBtnWrap>
        </S.LeftContainer>
        <S.RightContainer>
          <NextImageWithFallback
            src={`/assets/images/home/v4/pc_kdcdiv_big_${course.slug}.png`}
            height={360}
            width={318}
            alt={`${course.courseTitle}`}
          />
        </S.RightContainer>
      </S.ExpandedContainer>
      <S.UnExpandedContainer isExpanded={isExpanded}>
        <S.UnExpandedTitle>{course.subTitle}</S.UnExpandedTitle>
        <NextImageWithFallback
          src={`/assets/images/home/v4/pc_kdcdiv_small_${course.slug}.png`}
          height={198}
          width={150}
          alt={`${course.courseTitle}`}
        />
      </S.UnExpandedContainer>
    </S.Container>
  );
};
