import * as S from "./carousel.style";
import { useIsGnbMobile } from "@/businesslogics/_layout/window";
import { CarouselSlide } from "src/models/home.d";

export const BannerCarouselItem = ({
  carousel,
}: {
  carousel: CarouselSlide;
}) => {
  const parse = require("html-react-parser");
  const isMobile = useIsGnbMobile();
  const getImageSrc = () => {
    if (isMobile) {
      return carousel.background.mobile;
    } else {
      return carousel.background.desktop;
    }
  };

  return (
    <S.ItemWrapper bg={carousel.background}>
      <S.ItemTextContainer>
        <S.ItemTitle>
          {typeof carousel.title === "string"
            ? parse(carousel.title)
            : carousel.title}
        </S.ItemTitle>
        <S.ItemSubTitle>{carousel.desc}</S.ItemSubTitle>
      </S.ItemTextContainer>

      <S.ItemImage src={getImageSrc()}></S.ItemImage>
    </S.ItemWrapper>
  );
};
