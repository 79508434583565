import styled from "@emotion/styled";
import { DisplayResolution } from "@/styles/themes/device";
import { mCaption2, neutralDay, wCaption1 } from "@teamsparta/design-system";

export const Wrapper = styled.section`
  padding: 0 16px;
  width: 100%;
  max-width: 1152px;
  ${DisplayResolution.TabletAndDesktop} {
    align-self: center;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  isolation: isolate;
  height: 122px;

  ${DisplayResolution.TabletAndDesktop} {
    height: 240px;
  }
`;

export const CarouselItemContainer = styled.div<{ transition }>`
  display: flex;
  width: 100%;
  height: 100%;
  transition: ${({ transition }) =>
    transition ? "all 500ms ease-in-out" : ""};
  transform-style: preserve-3d;
`;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: fit-content;
  min-width: 50px;
  padding: 0 10px;
  border-radius: 40px;
  background: rgba(20, 22, 23, 0.6);
  ${mCaption2};
  color: #ffffff99;
  cursor: default;
  height: 26px;

  ${DisplayResolution.TabletAndDesktop} {
    padding: 0 4px;
    min-width: 74px;
    right: 16px;
    bottom: 16px;
    height: 32px;
  }
`;

export const NumberWrap = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
`;

export const Number = styled.p`
  ${mCaption2};
  color: ${neutralDay.white};

  ${DisplayResolution.TabletAndDesktop} {
    ${wCaption1};
    color: ${neutralDay.white};
  }
`;

export const TotalNumber = styled(Number)`
  opacity: 0.6;
`;

export const ArrowWrapper = styled.div`
  display: none;
  ${DisplayResolution.TabletAndDesktop} {
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
  }
`;
