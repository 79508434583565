import instance from "axios";
import { useQuery } from "react-query";
import { HomeSignatureDto } from "@/businesslogics/home/SignatureDto";
import { CarouselDto } from "@/businesslogics/home/CarouselDto.d";

const getHomeCarouselSlides = async () => {
  try {
    const response = await instance.get<CarouselDto>(
      `${process.env.API_URL}/main-carousel`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useHomeCarouselSlides = () => {
  return useQuery<CarouselDto | undefined, Error>("homeCarouselSlides", () =>
    getHomeCarouselSlides(),
  );
};

const getHomeSignatureBundles = async () => {
  try {
    const response = await instance.get<HomeSignatureDto>(
      `${process.env.API_URL}/catalog/main/signature`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useHomeSignatureBundles = () => {
  return useQuery<HomeSignatureDto | undefined, Error>("homeSignature", () =>
    getHomeSignatureBundles(),
  );
};

const getHomeTotalEnrolleds = async () => {
  try {
    const response = await instance.get<number>(
      `${process.env.API_URL}/social-proof/total-enrolleds`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useHomeTotalEnrolleds = () => {
  return useQuery<number | undefined, Error>("totalEnrolleds", () =>
    getHomeTotalEnrolleds(),
  );
};
