import { MiniSpartaLogo } from "src/components/atoms/Icons/SpartaLogo/MiniSpartaLogo.svg";
import * as S from "./hero.style";
import { RollingWrap } from "src/components/atoms/RollingWrap/RollingWrap";
import { CategoryIcons } from "src/models/home/homeV4.models";
import { useRouter } from "next/router";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { ImageWrapperWithFallback } from "src/components/atoms/_common/ImageWrapperWithFallback/ImageWrapperWithFallback";
import { useCustomRemoteConfig } from "src/businesslogics/_common/hackle/useCustomRemoteConfig";

export const Hero = () => {
  const router = useRouter();
  const onClickCategoryIcons = (nextUrl: string, categoryTitle: string) => {
    sendCPLog("scc_mainPage_category_click", {
      category_title: categoryTitle,
    });
    router.push(nextUrl);
  };

  const rollingTexts = useCustomRemoteConfig("scc_main_rolling_text", "json");

  return (
    <S.HeroSection>
      <S.BackGroundColor>
        <S.BackGroundInnerColor />
      </S.BackGroundColor>

      <S.ContentsWrap>
        <S.ContentsContainer>
          <S.TitleContainer>
            {/* 인원 이슈로 인해 임시로 주석처리
            <S.SubTitle>
              {countUpNumber.toLocaleString()}명이 코딩으로 함께 큰일 내는 중
            </S.SubTitle> */}
            <S.TitleInnerContainer>
              <S.RollingTextWrap>
                {rollingTexts && (
                  <RollingWrap>
                    {rollingTexts?.rollingText?.map((text, index) => (
                      <S.Title key={text}>{text}</S.Title>
                    ))}
                  </RollingWrap>
                )}
              </S.RollingTextWrap>

              <S.TitleRed>
                스파르타코딩클럽
                <S.IconWrap>
                  <MiniSpartaLogo />
                </S.IconWrap>
              </S.TitleRed>
            </S.TitleInnerContainer>
          </S.TitleContainer>

          <S.IconContainer>
            {CategoryIcons().map((icon, index) => (
              <S.LabelIconContainer
                key={index}
                onClick={() => onClickCategoryIcons(icon.nextUrl, icon.title)}
              >
                <>{icon.badge && <S.IconBadge>{icon.badge}</S.IconBadge>}</>
                <ImageWrapperWithFallback>
                  <S.Icon src={icon.src} alt={`${icon.title}-icon`} />
                </ImageWrapperWithFallback>
                <S.IconLabel>{icon.title}</S.IconLabel>
              </S.LabelIconContainer>
            ))}
          </S.IconContainer>
        </S.ContentsContainer>
      </S.ContentsWrap>
    </S.HeroSection>
  );
};
