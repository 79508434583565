import { useEffect, useState } from "react";

export const useInfiniteCarousel = (slides) => {
  const [carousel, setCarousel] = useState([]);
  useEffect(() => {
    if (!!slides) {
      setCarousel([slides[slides.length - 1], ...slides, slides[0]]);
    }
  }, [slides]);
  return carousel;
};
