import { useEffect, useRef, useState } from "react";
import { useInfiniteCarousel } from "@/businesslogics/home/v2/Carousel";
import * as S from "./bannerCarousel.style";
import { useHomeCarouselSlides } from "@/queries/home";
import { defaultCarouselData } from "@/models/home/carousel.models";
import { BannerCarouselItems } from "./BannerCarouselItems";
import { useInterval } from "@/businesslogics/community/review";
import {
  ArrowLeftSmallLine,
  ArrowRightSmallLine,
  neutralDay,
} from "@teamsparta/design-system";
import { sendCPLog } from "@teamsparta/cross-platform-logger";

export let CarouselRef;
const BannerCarousel = () => {
  const SLIDE_TIME = 1000;
  const [currentSlide, setCurrentSlide] = useState(1);
  const [transition, setTransition] = useState(false);

  const carouselData = useHomeCarouselSlides();
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    if (carouselData.isSuccess) {
      setSlides(
        !!carouselData.data?.slides.length
          ? carouselData.data.slides
          : defaultCarouselData
      );
    }
  }, [carouselData.isSuccess]);

  const infiniteSlide = useInfiniteCarousel(slides);
  const initSlideSize = slides.length;
  const slideRef = useRef(null);
  CarouselRef = useRef(null);
  const innerWidth = CarouselRef.current?.clientWidth || 0;

  const [isMoving, setIsMoving] = useState(false);

  const replaceSlide = (index: number): void => {
    setTimeout(() => {
      setTransition(false);
      setCurrentSlide(index);
    }, SLIDE_TIME);
  };

  const getSlideRealIdx = (idx: number): number =>
    idx % initSlideSize === 0 ? initSlideSize : idx % initSlideSize;

  const handleSlide = (direction) => {
    if (!isMoving) {
      setIsMoving(true);
      let index = currentSlide + direction;
      setCurrentSlide(index);

      if (index !== getSlideRealIdx(index)) {
        replaceSlide(getSlideRealIdx(index));
      }

      setTransition(true);
      setTimeout(() => setIsMoving(false), SLIDE_TIME - 1);
    }
  };

  const culcCurrentSlide = (index): number => {
    if (index === slides.length + 1) return 1;
    if (index === 0) return slides.length;
    return index;
  };

  useInterval(() => {
    handleSlide(+1);
  }, 5000);

  const [mouseDownClientX, setMouseDownClientX] = useState<number>(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [dragSlideX, setDragSlideX] = useState(0);

  const onMouseDown = (e) => {
    setTransition(false);
    setIsMouseDown(true);

    if (e.type === "touchstart") {
      setMouseDownClientX(e.touches[0].pageX);
    } else {
      setMouseDownClientX(e.pageX);
    }
  };

  const onMouseUp = (e) => {
    setTransition(true);
    setIsMouseDown(false);
  };

  const onMouseMove = (e) => {
    if (!isMouseDown) return;

    if (e.type === "touchmove") {
      setDragSlideX(mouseDownClientX - e.touches[0].pageX);
    } else {
      setDragSlideX(mouseDownClientX - e.pageX);
    }
  };

  useEffect(() => {
    slideRef.current.style.transform = `translate3d(-${
      currentSlide * innerWidth + dragSlideX
    }px, 0, 0)`;
  }, [currentSlide, dragSlideX, innerWidth]);

  const getSlideRatio = (dragSlideX) => {
    return Math.floor((dragSlideX / innerWidth) * 100);
  };

  useEffect(() => {
    if (dragSlideX === 0) return;
    if (getSlideRatio(dragSlideX) > 20 && !isMouseDown) {
      handleSlide(+1);
      setDragSlideX(0);
    } else if (getSlideRatio(dragSlideX) < -20 && !isMouseDown) {
      handleSlide(-1);
      setDragSlideX(0);
    } else if (!isMouseDown) {
      setDragSlideX(0);
    }
  }, [dragSlideX, isMouseDown]);

  const isStartOfSlide = culcCurrentSlide(currentSlide) === 1;
  const isEndOfSlide = culcCurrentSlide(currentSlide) === slides.length;

  return (
    <S.Wrapper>
      <S.CarouselContainer ref={CarouselRef}>
        <S.CarouselItemContainer
          ref={slideRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchStart={onMouseDown}
          onTouchEnd={onMouseUp}
          onTouchMove={onMouseMove}
          transition={transition}
        >
          {!infiniteSlide.includes(undefined) &&
            infiniteSlide.map((carousel, idx) => {
              return (
                <BannerCarouselItems
                  key={"item-" + carousel.cta.link + idx}
                  carousel={carousel}
                  isMoving={isMoving}
                />
              );
            })}
        </S.CarouselItemContainer>
        <S.IndicatorContainer>
          <S.ArrowWrapper
            onClick={() => {
              handleSlide(-1);
              sendCPLog("scc_mainPage_carousel_Nav_click", {
                button_text: currentSlide,
              });
            }}
          >
            <ArrowLeftSmallLine
              size={16}
              color={
                isStartOfSlide ? "rgba(255, 255, 255, 0.2)" : neutralDay.white
              }
            />
          </S.ArrowWrapper>
          <S.NumberWrap>
            <S.Number>{culcCurrentSlide(currentSlide)}</S.Number>
            <S.TotalNumber>/</S.TotalNumber>
            <S.TotalNumber> {slides.length}</S.TotalNumber>
          </S.NumberWrap>
          <S.ArrowWrapper
            onClick={() => {
              handleSlide(+1);
              sendCPLog("scc_mainPage_carousel_Nav_click", {
                button_text: currentSlide,
              });
            }}
          >
            <ArrowRightSmallLine
              size={16}
              color={
                isEndOfSlide ? "rgba(255, 255, 255, 0.2)" : neutralDay.white
              }
            />
          </S.ArrowWrapper>
        </S.IndicatorContainer>
      </S.CarouselContainer>
    </S.Wrapper>
  );
};

export default BannerCarousel;
