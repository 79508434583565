import * as S from "./courseCard.style";
import { ImageWrapperWithFallback } from "src/components/atoms/_common/ImageWrapperWithFallback/ImageWrapperWithFallback";
import { useRouter } from "next/router";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { CourseCardType } from "src/models/home/homeV4.models";

type CourseCardProps = {
  courseCard: CourseCardType;
};

export const CourseCard = ({ courseCard }: CourseCardProps) => {
  const router = useRouter();
  const onClickCard = () => {
    router.push(courseCard.url);
    sendCPLog("scc_mainPage_freeCard_click", {
      course_title: courseCard.title,
      course_id: courseCard.courseId,
      is_free: true,
    });
  };

  const onClickCta = (buttonText) => {
    router.push(`${process.env.ACADEMIA_URL}/payment/${courseCard.courseId}`);
    sendCPLog("scc_mainPage_freeCard_apply_click", {
      button_text: buttonText,
      course_title: courseCard.title,
      course_id: courseCard.courseId,
      is_free: true,
    });
  };

  return (
    <S.Container>
      <ImageWrapperWithFallback>
        <S.CardThumb src={courseCard.keyword} onClick={onClickCard} />
      </ImageWrapperWithFallback>
      <S.CardTextWrap>
        <S.CtaButton onClick={() => onClickCta("바로 수강신청")}>
          <ImageWrapperWithFallback>
            <img src={"/assets/icons/icon_thunder.svg"} alt={"icon_thunder"} />
          </ImageWrapperWithFallback>
          <S.CtaText>바로 수강신청</S.CtaText>
        </S.CtaButton>
        <S.CardTitleContainer onClick={onClickCard}>
          <S.Title>{courseCard.title}</S.Title>
          <S.Desc>{courseCard.description}</S.Desc>
        </S.CardTitleContainer>
        <S.Price>{courseCard.price}</S.Price>
      </S.CardTextWrap>
    </S.Container>
  );
};
