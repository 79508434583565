import React, { useRef } from "react";
import { KdcRecommendType } from "src/models/home/homeV4/KdcRecommend.models";
import * as S from "./kdcCourseCard.style";
import { NextImageWithFallback } from "src/components/atoms/_common/NextImageWithFallback/NextImageWithFallback";
import { useRouter } from "next/router";
import { sendLogV2 } from "src/businesslogics/_common/loggingV2";

type Props = {
  courseData: KdcRecommendType;
  isActive: boolean;
};

export const KdcCourseCard = ({ courseData, isActive }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const onClickItem = (courseTitle, nextUrl) => {
    sendLogV2("lecture_courseCuration_apply_click", router.pathname, {
      course_title: courseTitle,
    });
    router.push(nextUrl);
  };

  return (
    <S.Container
      ref={ref}
      bgColor={courseData.bgColor}
      isCenter={isActive}
      onClick={() => {
        onClickItem(courseData.courseTitle, courseData.nextUrl);
      }}
    >
      <S.TitleContainer>
        <S.TitleBadge>
          <S.Title>{courseData.courseTitle}</S.Title>
        </S.TitleBadge>
        <S.SubTitle>{courseData.subTitle}</S.SubTitle>
        <S.Desc>{courseData.description}</S.Desc>
      </S.TitleContainer>
      <NextImageWithFallback
        src={`/assets/images/home/v4/mobile_kdcdiv_${courseData.slug}.webp`}
        width={300}
        height={160}
        alt={`${courseData.courseTitle}`}
      />
    </S.Container>
  );
};
